import GamejamTracking from 'gamejam-tracking-js'
import { PLAYCHAIN_SETTING } from "@/utils/constants"

let trackingInstance
let trackingWalletAddress
function isTrackingReady() {
    return trackingWalletAddress != null && trackingInstance != null
}
function getTracking(address) {
    if ((!trackingInstance || address != trackingWalletAddress) && address != null) {
        trackingWalletAddress = address
        trackingInstance = new GamejamTracking(
            PLAYCHAIN_SETTING.APP_ID,
            PLAYCHAIN_SETTING.APP_SECRET,
            {
                flushAt: 1,
                flushInterval: 1000,
                userId: `eth:${address}`
            })
    }
    return trackingInstance;
}
function logoutTracking() {
    trackingInstance = null
}
function setWallet(address) {
    if (trackingWalletAddress == address) {
        return
    }
    trackingWalletAddress = address
    trackingInstance = new GamejamTracking(
        PLAYCHAIN_SETTING.APP_ID,
        PLAYCHAIN_SETTING.APP_SECRET,
        {
            flushAt: 1,
            flushInterval: 1000,
            userId: `eth:${address}`
        })

    trackingInstance.trackWalletLink(address, 'eth')
}
const trackingManager = {
    getTracking,
    logoutTracking,
    setWallet,
    isTrackingReady
}
export default trackingManager;