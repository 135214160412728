import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/common.css'
import './assets/styles/animation.css'
import VueKinesis from 'vue-kinesis'
import VueGtag from 'vue-gtag'
import LazyLoadDirective from "./directives/LazyLoad";
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueKinesis)
Vue.use(VueGtag, {
  config: {
    id: 'G-LYH518GKP4'
  }
})
 
Vue.use(VueGoogleMaps, {
  load: {
		key: 'AIzaSyAAH-WhvBsVd4IsyM1cbMjR4OfVCBL-A98',
  },
})

Vue.config.productionTip = false
Vue.directive("lazyload", LazyLoadDirective);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
