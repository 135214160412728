import { request, collabRequest, eventRequest } from '../utils/requests'

export async function APIRequestChallenge(publicAddress) {
  return request({
    url: '/auth/challenge',
    method: 'post',
    data: {
      publicAddress
    }
  })
}

export async function APIVerify({ address, signature }) {
  return request({
    url: '/auth/verify',
    method: 'post',
    data: {
      publicAddress: address,
      signature
    }
  })
}

export async function APIReroll(session_id) {
  return request({
    url: `/session/${session_id}/re-roll`,
    method: 'post'
  })
}

export async function APIPremint(session_id) {
  return request({
    url: `/session/${session_id}/pre-mint`,
    method: 'post'
  })
}

export async function APIUpdate({ id, hash }) {
  return request({
    url: `/session/${id}/update`,
    method: 'post',
    params: {
      tx_hash: hash
    }
  })
}

export async function APIFetchContract() {
  return request({
    url: '/public/info',
    method: 'get'
  })
}

export async function APICheckWallet(address) {
  return request({
    url: `/public/whitelist?address=${address}`,
    method: 'get',
  })
}

export async function APIRequestDiscordChallenge({guildId, discordId, code, address}) {
  return collabRequest({
    url: `/challenge`,
    method: 'get',
    params: {
      guildId: guildId,
			discordId: discordId,
			code: code,
			publicAddress: address
    }
  })
}


export async function APIVerifyDiscord({guildId, discordId, signature, address}) {
  return collabRequest({
    url: `/verify`,
    method: 'post',
    data: {
      guildId: guildId,
			discordId: discordId,
			signature: signature,
			publicAddress: address
    }
  })
}


export async function APIRegisterWASDLondon(data) {
	return eventRequest({
		url: `/event/2/register`,
		method: 'post',
		data
	})
}

export async function APIFetchWASDLondon() {
	return eventRequest({
		url: `/event/2`,
		method: 'get',
	})
}

export async function APIVerifyArt({ publicAddress, signature, wasdTokenIds }) {
  return request({
    url: '/auth/verify-art',
    method: 'post',
    data: {
      publicAddress,
      signature,
      wasdTokenIds
    }
  })
}

export async function APIFetchClaimableRules(userAddress) {
  return request({
    url: '/public/wasd-art-ids',
    method: 'get',
    params: {
      userAddress
    }
  })
}

export async function APIFetchDownloadChallenge(publicAddress) {
  return request({
    url: '',
    method: 'post',
    params: {
      publicAddress
    }
  })
}

export async function APIFetchDownloadURL(wasdTokenId) {
  return request({
    url: '/public/model-download',
    method: 'post',
    data: {
      wasdTokenId
    }
  })
}
