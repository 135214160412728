export function isJSONSerializable(value) {
  if (value === undefined) {
    return false
  }
  const t = typeof value
  if (t === "string" || t === "number" || t === "boolean" || t === null) {
    return true
  }
  if (t !== "object") {
    return false // bigint, function, symbol, undefined
  }
  if (Array.isArray(value)) {
    return true
  }
  return (value.constructor && value.constructor.name === "Object") ||
    typeof value.toJSON === "function"
}

const payloadMethods = new Set(Object.freeze(["PATCH", "POST", "PUT", "DELETE"]))

export function isPayloadMethod(method = "GET") {
  return payloadMethods.has(method.toUpperCase())
}
