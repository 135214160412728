import SuperfineSDK from 'superfine-sdk-js/lib/index.esm'
import {PLAYCHAIN_SETTING} from "@/utils/constants"

let superfineSDK

function setupSDK() {
  superfineSDK = new SuperfineSDK(
    PLAYCHAIN_SETTING.APP_ID,
    PLAYCHAIN_SETTING.APP_SECRET,
    {
      autoStart: false,
      flushAt: 1,
      flushInterval: 1000,
      attributionDisable: true
    }
  )
}

export function runSDK() {
  if (!superfineSDK) {
    setupSDK()
  }
  superfineSDK.start()
}

export function attributionWallet(walletAddress, walletName) {
  if (!superfineSDK) {
    setupSDK()
  }
  superfineSDK.logAccountLogin(walletAddress, walletName)
}
