export type GamejamTrackingCoreOptions = {
  // The number of events to queue before sending to Gamejam Tracking (flushing)
  flushAt?: number
  // The interval in milliseconds between periodic flushes
  flushInterval?: number
  // If set to false, tracking will be disabled until `optIn` is called
  enable?: boolean
  // How many times we will retry HTTP requests
  fetchRetryCount?: number
  // The delay between HTTP request retries
  fetchRetryDelay?: number
  // For Session Analysis how long before we expire a session (defaults to 30 mins)
  sessionExpirationTimeSeconds?: number
  
  waitConfigId?: boolean
  customUserId?: boolean
  userId?: string
}

export enum GamejamTrackingPersistedProperty {
  UserId = 'user_id',
  Props = 'props',
  Queue = 'queue',
  OptedOut = 'opted_out',
  SessionId = 'session_id',
  SessionLastTimestamp = 'session_timestamp'
}

export type GamejamTrackingFetchOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH'
  mode?: 'no-cors'
  credentials?: 'omit'
  headers: { [key: string]: string }
  body: string
}

export type GamejamTrackingFetchResponse = {
  status: number
  text: () => Promise<string>
  json: () => Promise<any>
}

export type GamejamTrackingQueueItem = {
  message: any
  callback?: (err: any) => void
}

export type GamejamTrackingEventProperties = {
  [key: string]: any
}

export type GamejamTrackingAutotrackElement = {
  $el_text?: string
  tag_name: string
  href?: string
  nth_child?: number
  nth_of_type?: number
  order?: number
} & {
  [key: string]: any
} // Any key prefixed with `attr__` can be added

export type GamejamTrackingDecideResponse = {
  config: { enable_collect_everything: boolean }
  editorParams: { toolbarVersion: string; jsURL: string }
  isAuthenticated: true
  supportedCompression: string[]
  sessionRecording: boolean
}

export type JSONPrimitive = string | number | boolean | null;
export type JSONValue = JSONPrimitive | JSONObject | JSONArray;
export type JSONObject = { [member: string]: JSONValue };
export type JSONArray = JSONValue[];