import axios from 'axios'
import {API_URL, COLLAB_API_URL, EVENT_URL, WAES_URL} from './constants'
import store from '../store'
import { withBase } from "ufo";
import {isJSONSerializable, isPayloadMethod} from "@/utils/index";

function getService(baseURL) {
  const service = axios.create({
    baseURL,
    timeout: 40000
  })

  service.interceptors.request.use(
    (config) => {
      if (store.getters.accessToken) {
        config.headers['Authorization'] = `Bearer ${store.getters.accessToken}`
      }
      return config
    }
  )

  service.interceptors.response.use(
      (res) => {
        return { data: res.data.data, status: res.status }
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)
            ? error.response.data.message : null
        const status = error.response ? error.response.status : 0
        const data = error.response && error.response.data && error.response.data.data

        const responseError = { message, status, data }
        return Promise.reject(responseError)
      }
  )

  return service
}

const service = getService(API_URL)

function getCollabService(baseURL) {
  const service = axios.create({
    baseURL,
    timeout: 40000
  })

  service.interceptors.response.use(
      (res) => {
        return { data: res.data.data, status: res.status }
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message)
            ? error.response.data.message : null
        const status = error.response ? error.response.status : 0
        const data = error.response && error.response.data && error.response.data.data

        const responseError = { message, status, data }
        return Promise.reject(responseError)
      }
  )

  return service
}

function getEventService(baseURL) {
	const service = axios.create({
		baseURL,
		timeout: 40000
	})

	service.interceptors.response.use(
		(res) => {
			return { data: res.data.data, message: res.data.message, status: res.status }
		},
		(error) => {
        const message = (error.response && error.response.data && error.response.data.message)
            ? error.response.data.message : null
        const status = error.response ? error.response.status : 0
        const data = error.response && error.response.data && error.response.data.data

        const responseError = { message, status, data }
        return Promise.reject(responseError)
		}
	)
	return service
}

const collabService = getCollabService(COLLAB_API_URL)
const eventService = getEventService(EVENT_URL)

export const request = async req => service(req)
export const collabRequest = async req => collabService(req)
export const eventRequest = async req => eventService(req)

export function waesRequest(_request, _options = {}) {
  const baseOptions = {
    method: 'get',
    credentials: 'include',
    headers: {}
  }
  const request = withBase(_request, WAES_URL)
  const options = {
    ...baseOptions,
    ..._options
  }
  if (options.body && isPayloadMethod(options.method) && isJSONSerializable(options.body)) {
    options.body = typeof options.body === 'string' ? options.body : JSON.stringify(options.body)
    options.headers = new Headers(options.headers)
    if (!options.headers.has('content-type')) {
      options.headers.set('content-type', 'application/json')
    }
    if (!options.headers.has('accept')) {
      options.headers.set('accept', 'application/json')
    }
  }
  return fetch(request, options)
}
