import Vue from 'vue';
import Vuex from 'vuex';
import walletApi from "@/utils/web3";
import { CONTRACT_ADDRESS, Ethereum, supportedChainIds, BSCTestnet } from "@/utils/constants";
import {
  APIFetchContract,
  APIRequestChallenge,
  APIRequestDiscordChallenge,
  APIVerifyDiscord,
  APIVerify
} from "@/api";
import axios from "axios";
import { appLocalStorage } from '../utils/app-storage';
import trackingManager from '@/utils/tracking-manager';

Vue.use(Vuex);
// let gamejamTracking
export default new Vuex.Store({
  state: {
    notification: {
      visible: false,
      message: '',
      type: 'error'
    },
    theme: 'dark',
    chains: [Ethereum, BSCTestnet],
    wallet: {
      installed: false
    },
    balance: 0,
    challenge: '',
    discordChallenge: '',
    session: null,
    info: null,
    contract: {
      wasd: CONTRACT_ADDRESS.WASD,
      minting: CONTRACT_ADDRESS.WASD_MINTING
    },
    nft: [],
    tokenIds: [],
    nftPage: -1,
    nftTotalPage: 1,
    nftSize: 6,
    accessToken: appLocalStorage.getItem('wasd-access-token') || ''
  },
  getters: {
    theme: state => state.theme,
    chains: state => state.chains.filter(c => supportedChainIds.includes(c.id)),
    wallet: state => state.wallet,
    wrongNetwork: state => state.wallet && !supportedChainIds.includes(state.wallet.chainId),
    balance: state => state.balance,
    notificationVisible: state => state.notification.visible,
    notificationMessage: state => state.notification.message,
    notificationType: state => state.notification.type,
    challenge: state => state.challenge,
    discordChallenge: state => state.discordChallenge,
    session: state => state.session,
    info: state => state.info,
    wasdContract: state => state.contract.wasd,
    mintingContract: state => state.contract.minting,
    nfts: state => state.nft,
    nftPage: state => state.nftPage,
    nftTotalPage: state => state.nftTotalPage,
    nftSize: state => state.nftSize,
    canLoadMore: state => state.nfts.length < state.balance,
    tokenIds: state => state.tokenIds,
    accessToken: state => state.accessToken
  },
  mutations: {
    SET_WALLET(state, value) {
      state.wallet = {
        ...state.wallet,
        ...value
      };
      trackingManager.setWallet(state.wallet.address);
    },
    SET_BALANCE(state, value) {
      trackingManager.getTracking(state.wallet.address).track('nft_balance', { 'value': value });
      state.balance = value;
    },
    SET_THEME(state, value) {
      state.theme = value;
    },
    SET_NOTIFICATION(state, value) {
      state.notification = {
        ...state.notification,
        ...value
      };
    },
    SET_CHALLENGE(state, value) {
      state.challenge = value;
    },
    SET_SESSION(state, value) {
      state.session = value;
    },
    SET_TX(state, value) {
      state.transaction = value;
    },
    SET_INFO(state, value) {
      state.info = value;
    },
    RESET_WALLET(state) {
      state.wallet = {
        installed: false
      };
      appLocalStorage.removeItem('cur_wallet');
      window.location.reload();
    },
    SET_CONTRACT(state, value) {
      state.contract = value;
    },
    SET_NFT(state, value) {
      state.nft = value;
    },
    SET_NFT_PAGE(state, value) {
      state.nftPage = value;
    },
    SET_NFT_TOTAL_PAGE(state, value) {
      state.nftTotalPage = value;
    },
    SET_TOKEN_IDS(state, value) {
      state.tokenIds = value;
    },
    SET_DISCORD_CHALLENGE(state, value) {
      state.discordChallenge = value;
    },
    CLEAR_WALLET(state) {
      state.wallet = {
        installed: false
      };
      appLocalStorage.removeItem('cur_wallet');
    },
    SET_CLAIM_SESSION(state, value) {
      const { signature, wasdArtRules } = value;
      state.claimSignature = signature;
      state.claimable = wasdArtRules;
    }
  },
  actions: {
    updateWallet({ commit }, value) {
      commit('SET_WALLET', value);
    },
    async fetchBalance({ getters, commit }) {
      const value = await getters.wallet.getWASDBalance();
      commit('SET_BALANCE', value);
    },
    changeTheme({ state, commit }) {
      if (state.theme === 'dark') {
        commit('SET_THEME', 'light');
      } else {
        commit('SET_THEME', 'dark');
      }
    },
    hideNotification({ commit }) {
      commit('SET_NOTIFICATION', { visible: false });
    },
    showNotification({ commit }, { message, type = 'error' }) {
      commit('SET_NOTIFICATION', {
        visible: true,
        message,
        type
      });
    },
    async connectWallet({ dispatch, state }, { name, isCached }) {
      await walletApi.init(name, isCached);
      if (name !== 'Wallet Connect') {
        await dispatch('fetchChallenge');
        await dispatch('verifyChallenge');
      }
      trackingManager.setWallet(state.wallet.address);
    },
    async fetchDiscordChallenge({ state, commit, dispatch }, { guildId, discordId, code }) {
      try {
        const address = state.wallet.address;
        const { data: { challenge } } = await APIRequestDiscordChallenge(
          { guildId, discordId, code, address }
        );
        commit('SET_DISCORD_CHALLENGE', challenge);
        return true;
      } catch (e) {
        if (e.code === 4001) {
          await dispatch('showNotification', { message: 'User rejected transaction!' });
        } else {
          await dispatch('showNotification', { message: e.message });
          console.error(e);
        }
        return false;
      }
    },
    async verifyDiscordChallenge({ state, dispatch }, { guildId, discordId }) {
      try {
        const challenge = state.discordChallenge;
        const address = state.wallet.address;
        const signature = await walletApi.getSignature({ challenge, address });
        await APIVerifyDiscord({ guildId, discordId, signature, address });
        return true;
      } catch (e) {
        console.error('Error Verifying Challenge: ', e);
        await dispatch('showNotification', { message: 'Incorrect signature' });
        return false;
      }
    },
    async fetchChallenge({ state, commit, dispatch }) {
      try {
        const address = state.wallet.address;
        const { data: { challenge } } = await APIRequestChallenge(address);
        commit('SET_CHALLENGE', challenge);
      } catch (e) {
        if (e.code === 4001) {
          await dispatch('showNotification', { message: 'User rejected transaction!' });
        } else {
          await dispatch('showNotification', { message: 'Some errors occur. Please reload the page and try again!' });
          console.log(e);
        }
      }
    },
    async verifyChallenge({ state, commit }) {
      try {
        const challenge = state.challenge;
        const address = state.wallet.address;
        const signature = await walletApi.getSignature({ challenge, address });
        const { data: session } = await APIVerify({ address, signature });
        commit('SET_SESSION', session);
      } catch (e) {
        console.error('Error Verifying Challenge: ', e);
      }
    },
    async fetchInfo({ state, commit }) {
      try {
        if (!state.session) return;
        const { role_id } = state.session;
        const address = state.wallet.address;
        const phase = await walletApi.getCurrentPhase();
        const total = await walletApi.getTotalSupply();
        let minted = await walletApi.getMintedNFT();
        const phaseInfo = await walletApi.getPhaseInfo();
        const roles = await walletApi.getRoles();
        if (phaseInfo.length >= +phase && role_id) {
          const info = await walletApi.getParticipantInfo(address);
          const mint_left = +info.mintCount ? info.availableMintCount : +roles[role_id][2] - +info.mintCount;
          commit('SET_INFO', {
            mint_left: phase >= 1 ? phaseInfo[phase - 1].mintLimit < mint_left ? phaseInfo[phase - 1].mintLimit : mint_left : mint_left,
            role: +info.mintCount ? info.roleName : roles[role_id][1],
            phases: +info.mintCount ? info.allowedInPhases : +role_id ? ['1', '2'] : ['2'],
            mint: +info.mintCount,
            roles,
            phase,
            total,
            minted,
            mintOver: +phase === phaseInfo.length && +info.availableMintCount === 0
          });
        } else {
          commit('SET_INFO', {
            phase,
            total,
            minted,
            phases: [],
            mintOver: true
          });
        }
      } catch (e) {
        console.error('Error getting participant info: ', e);
      }
    },
    reload() { // { commit }
      walletApi.closeConnector();
      // commit('RESET_WALLET')
    },
    async switchWallet({ commit, dispatch }, { address }) {
      commit('SET_WALLET', { address });
      commit('SET_SESSION', null);
      dispatch('fetchChallenge');
    },
    async fetchContract({ commit }) {
      const { data: { mintingAddress, nftAddress } } = await APIFetchContract();
      commit('SET_CONTRACT', {
        wasd: nftAddress,
        minting: mintingAddress
      });
    },
    async fetchTokenIds({ state, dispatch, commit }) {
      await dispatch('fetchBalance');
      const tokenIds = await Promise.all(Array.from({ length: state.balance }).map((_, i) => {
        return state.wallet.getWASDTokenId(i);
      }));

      var totalPage = Math.ceil(state.balance / state.nftSize);

      commit('SET_NFT_TOTAL_PAGE', totalPage);
      commit('SET_TOKEN_IDS', tokenIds);
    },
    async fetchNFT({ state, commit }, { page }) {
      // if (state.tokenIds.length === state.nft.length) return
      const tokenIds = state.tokenIds.slice(page * state.nftSize, (page + 1) * state.nftSize);
      if (!tokenIds.length) return;
      const tokenURIs = tokenIds.map(id => `https://wasdnft.com/cdn/wasd/metadata/${id}.json`)
      let tokens = [];
      for (const uri of tokenURIs) {
        try {
          const { data } = await axios.get(uri);
          tokens.push({ ...data, image: data.image.replace('ipfs://', 'https://aquamarine-fancy-koala-317.mypinata.cloud/ipfs/') });
        } catch (e) {
          tokens.push({});
        }
      }

      commit('SET_NFT_PAGE', page);
      // commit('SET_NFT', [...state.nft, ...tokens])
      commit('SET_NFT', [...tokens]);
    },
    async setWallet({ state }, { wallet }) {
      state.wallet = wallet
    },
    async logout({ state }) {
      const address = state.wallet.address
      state.wallet = {}
      appLocalStorage.removeItem(`waes-sid-${address}`)
      appLocalStorage.removeItem('wasd-wallet-name')
      appLocalStorage.removeItem('wasd-wallet-address')
    },
  },
  modules: {}
});
