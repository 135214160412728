import {
  GamejamTrackingCore,
  GamejamTrackingFetchOptions,
  GamejamTrackingFetchResponse,
  GamejamTrackingPersistedProperty,
} from '../../gamejam-tracking-core/src'

import { getContext } from './context'
import { GamejamTrackingStorage, getStorage } from './storage'
import { version } from '../package.json'
import { GamejamTrackingOptions } from './types'

import timeZoneCityToCountry_data from './cities-to-countries.json';
const timeZoneCityToCountry = timeZoneCityToCountry_data as Record<string, string>;

import { UAParser } from './ua-parser'

export class GamejamTracking extends GamejamTrackingCore {
  private _storage!: GamejamTrackingStorage
  private _storageKey!: string
  
  private _storageCache: any

  constructor(appId: string, appSecret: string, options?: GamejamTrackingOptions) {
    super(appId, appSecret, options);
  }

  protected init(options?: GamejamTrackingOptions)
  {
    super.init(options);
    
    // gamejam-tracking-js stores options in one object on
    this._storageKey = options?.persistence_name ? `gamejam_tracking_${options.persistence_name}` : `gamejam_tracking_${this.appId}`
    this._storage = getStorage(options?.persistence || 'localStorage')
  }
  
  protected fillContextData(data: { [key: string]: any })
  {
    super.fillContextData(data);
    
    let parser = new UAParser();
    let result = parser.getResult();
           
    data[GamejamTrackingCore.BROWSER_KEY] = result.browser.name || '';
    data[GamejamTrackingCore.BROWSER_VERSION_KEY] = result.browser.version || '';
    
    data[GamejamTrackingCore.DEVICE_MODEL_KEY] = result.device.model || '';
    
    data[GamejamTrackingCore.OS_KEY] = result.os.name || '';
    data[GamejamTrackingCore.OS_VERSION_KEY] = result.os.version || '';
    
    let country: string = '';
    
    if (Intl)
    {
      let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let tzArr = userTimeZone.split("/");
      let userCity = tzArr[tzArr.length - 1];
      country = timeZoneCityToCountry[userCity] || '';
    }
    
    data[GamejamTrackingCore.COUNTRY_KEY] = country;
  }

  getPersistedProperty<T>(key: GamejamTrackingPersistedProperty): T | undefined {
    if (!this._storageCache) {
      this._storageCache = JSON.parse(this._storage.getItem(this._storageKey) || '{}') || {}
    }

    return this._storageCache[key]
  }

  setPersistedProperty<T>(key: GamejamTrackingPersistedProperty, value: T | null): void {
    if (!this._storageCache) {
      this._storageCache = JSON.parse(this._storage.getItem(this._storageKey) || '{}') || {}
    }

    if (value === null) {
      delete this._storageCache[key]
    } else {
      this._storageCache[key] = value
    }

    this._storage.setItem(this._storageKey, JSON.stringify(this._storageCache))
  }

  fetch(url: string, options: GamejamTrackingFetchOptions): Promise<GamejamTrackingFetchResponse> {
    return window.fetch(url, options)
  }
  getLibraryId(): string {
    return 'gamejam-tracking-js'
  }
  getLibraryVersion(): string {
    return version
  }
  getCustomUserAgent(): void {
    return
  }

  getCommonEventProperties(): any {
    return {
      ...super.getCommonEventProperties(),
      ...getContext(window),
    }
  }
}
