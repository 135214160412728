import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/LandingPage.vue')
  },
  {
    path: '/landing/wasd-customizer',
    name: 'image',
    component: () => import('@/views/ImagePage.vue')
  },
	{
		path: '/landing/london',
		name: 'wasdlondon',
		component: () => import('@/views/WASDLondon.vue')
	},
  {
		path: '/landing/og',
		name: 'wasdog',
		component: () => import('@/views/OGForm.vue')
	},
  {
    path: '/landing/portal',
    name: 'portal',
    component: () => import('@/views/PortalPage.vue')
  },
  {
    path: '/landing/playground',
    name: 'playground',
    component: () => import('@/views/PlaygroundPage.vue')
  },
  {
    path: '/landing/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/landing/faq',
    name: 'faq',
    component: () => import('@/views/FaqPage.vue')
  },
  {
    path: '/landing/license',
    name: 'license',
    component: () => import('@/views/LicensePage.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/VerifyPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'redirect',
    component: () => import('@/views/LandingPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PREFIX_DOMAIN  === 'no' ? '/' : process.env.VUE_APP_PUBLIC_PATH,
  routes
})

export default router
