<template>
  <transition name="notification">
    <div v-show="show" class="notification" :style="computedStyle">
      <div class="notification-header">
        <template v-if="type === 'error'">Error :(</template>
        <div @click="hide">
          <img alt src="../assets/images/close.svg"/>
        </div>
      </div>
      <p>
        {{ message }}
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NotificationMessage",
  computed: {
    show() {
      return !!this.$store.getters.notificationVisible
    },
    message() {
      return this.$store.getters.notificationMessage
    },
    type() {
      return this.$store.getters.notificationType
    },
    computedStyle() {
      const type = this.$store.getters.notificationType
      switch(type) {
        case 'success':
          return { '--c1': '#00D17F', '--c2': '#40BF55' }
        case 'warning':
          return { '--c1': '#FF8B33', '--c2': '#DF972A' }
        case 'error':
          return { '--c1': '#FF4452', '--c2': '#E65949'}
        default:
          return {}
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(v) {
        if(v) {
          setTimeout(() => {
            this.hide()
          }, 5000)
        }
      }
    }
  },
  methods: {
    hide() {
      this.$store.dispatch('hideNotification')
    }
  }
}
</script>

<style scoped lang="scss">
.notification {
  background: #0B0B0B;
  background: linear-gradient(180deg, #181818 0%, rgba(24, 24, 24, 0.5) 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border: 2px solid;
  border-image-source: linear-gradient(180deg, #FAF4EC 0%, rgba(250, 244, 236, 0.3) 100%);
  border-image-slice: 1;
  position: fixed;
  top: 80px;
  right: 32px;
  z-index: 10000;
  font-size: 16px;
  min-width: 200px;

  &-header {
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 14px;
    background: linear-gradient(98.37deg, var(--c1) 44.1%, var(--c2));
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    color: #181818;

    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: content-box;

      &:hover {
        cursor: pointer;
      }
    }
  }

  p {
    padding: 4px 16px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #FAF4EC;
  }
}

@media screen and (max-width: 600px) {
  .notification {
    top: 100px;
    right: 8px;
    font-size: 16px;
  }
}
</style>
