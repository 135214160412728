export const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';
export const COLLAB_API_URL = TARGET_MAINNET ? 'https://wasdnft.com/collab/bot/api' : 'https://demo.0m.gg/collab-bot/api';
export const API_URL = TARGET_MAINNET ? 'https://wasdnft.com/nft' : 'https://demo.0m.gg/wasdnft-minting';
export const EVENT_URL = TARGET_MAINNET ? 'https://iggv.org/api' : 'https://demo.iggv.org/api/';

export const ChainId = {
  Eth: TARGET_MAINNET ? '0x1' : '0x4',
  Bsc: TARGET_MAINNET ? 56 : '0x61'
};

export const supportedChainIds = [ChainId.Eth, ChainId.Bsc];

export const Ethereum = {
  id: 1,
  chainId: '0x1',
  chainName: 'Ethereum',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  rpcUrls: ['https://mainnet.infura.io/v3/'],
  blockExplorerUrls: ['https://etherscan.io'],
  icon: require('../assets/images/eth.svg')
};

export const BSCTestnet = {
  id: 97,
  chainId: '0x61',
  chainName: 'BSC Testnet',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'tBNB',
    decimals: 18
  },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  blockExplorerUrls: ['https://testnet.bscscan.com'],
  icon: ''
};

export const WAGMI_BSC_TESTNET = {
  "id": 97,
  "name": "Binance Smart Chain Testnet",
  "network": "bsc-testnet",
  "nativeCurrency": {
    "decimals": 18,
    "name": "BNB",
    "symbol": "tBNB"
  },
  "rpcUrls": {
    "default": {
      "http": [
        "https://data-seed-prebsc-1-s1.binance.org:8545/"
      ]
    },
    "public": {
      "http": [
        "https://data-seed-prebsc-1-s1.binance.org:8545/"
      ]
    }
  },
  "blockExplorers": {
    "etherscan": {
      "name": "BscScan",
      "url": "https://testnet.bscscan.com"
    },
    "default": {
      "name": "BscScan",
      "url": "https://testnet.bscscan.com"
    }
  },
  "contracts": {
    "multicall3": {
      "address": "0xca11bde05977b3631167028862be2a173976ca11",
      "blockCreated": 17422483
    }
  },
  "testnet": true
}

export const CONTRACT_ADDRESS = {
  WASD: TARGET_MAINNET ? '0x27013D274AA60c6e6883AA2130e8b01249EB11d4' : '0xDC34E9498B0C3d71CEab5f98528DeEB6b0449768',
  WASD_MINTING: TARGET_MAINNET ? '' : '0x9DF9653cf65e2b0EeC801528f80A0CaC766BA6eF',
  CLAIM: TARGET_MAINNET ? '' : '0x0d25017053E4BdE47048BA646eBD785ab6f20ACe',
  CORE: TARGET_MAINNET ? '' : ''
};

export const DEADLINE = process.env.VUE_APP_DEADLINE || '2022/04/15 18:30:00';

// export const BASE_IPFS = process.env.VUE_IPFS || 'https://opensea.mypinata.cloud/ipfs/'
export const BASE_IPFS = process.env.VUE_IPFS || 'https://ipfs.io/ipfs/';

export const PLAYCHAIN_SETTING = {
  APP_ID: "6305d51ed839600986a7f9d1",
  APP_SECRET: "bf25fd23847d440b8d0025ec032121ef"
};

export const WALLETCONNECT_PROJECT_ID = process.env.VUE_WALLETCONNECT_PROJECT_ID || '3c81c43a4eba8153872fdf89326ef757'

export const WAES_URL = TARGET_MAINNET ? 'https://wasdnft.com/waes-api' : 'https://demo.0m.gg/wasdcore-backend'
