import {
  GamejamTrackingFetchOptions,
  GamejamTrackingFetchResponse,
  GamejamTrackingQueueItem,
  GamejamTrackingAutotrackElement,
  GamejamTrackingDecideResponse,
  GamejamTrackingCoreOptions,
  GamejamTrackingEventProperties,
  GamejamTrackingPersistedProperty,
  JSONObject
} from './types'
import {
  assert,
  currentISOTime,
  currentTimestamp,
  generateUUID,
  removeTrailingSlash,
  retriable,
  RetriableOptions,
  safeSetTimeout,
  isNullOrEmpty
} from './utils'
export * as utils from './utils'
import { LZString } from './lz-string'
import { SimpleEventEmitter } from './eventemitter'

import { Buffer } from 'buffer'

export enum AdPlacement {
  UNKNOWN,
  BOTTOM,
  TOP,
  LEFT,
  RIGHT,
  FULL_SCREEN
}

function getAdPlacementString(adPlacement: AdPlacement): string
{
   switch (adPlacement)
   {
    case AdPlacement.FULL_SCREEN:
      return 'fullscreen';
      
    case AdPlacement.BOTTOM:
      return 'bottom';
      
    case AdPlacement.TOP:
      return 'top';
      
    case AdPlacement.LEFT:
      return 'left';
      
     case AdPlacement.RIGHT:
      return 'right';

    default:
      break;
  }
  
  return '';
}

export enum AdPlacementType {
  BANNER,
  INTERSTITIAL,
  REWARDED_VIDEO
}

function getAdPlacementTypeString(adPlacementType: AdPlacementType): string
{
   switch (adPlacementType)
   {
    case AdPlacementType.BANNER:
      return 'banner';
      
    case AdPlacementType.INTERSTITIAL:
      return 'interstitial';
      
    case AdPlacementType.REWARDED_VIDEO:
      return 'rewarded_video';
      
    default:
      break;
  }
  
  return '';
}

function getDefaultAdPlacement(adPlacementType: AdPlacementType): AdPlacement
{
  switch (adPlacementType)
  {
    case AdPlacementType.BANNER:
      return AdPlacement.BOTTOM;

    case AdPlacementType.INTERSTITIAL:
    case AdPlacementType.REWARDED_VIDEO:
      return AdPlacement.FULL_SCREEN;

    default:
      break;
  }

  return AdPlacement.FULL_SCREEN;
}

export enum AuthorizationTrackingStatus {
  NOT_DETERMINED,
  RESTRICTED,
  DENIED,
  AUTHORIZED
}

function getAuthorizationTrackingStatusString(authorizationTrackingStatus: AuthorizationTrackingStatus): string
{
   switch (authorizationTrackingStatus)
   {
    case AuthorizationTrackingStatus.AUTHORIZED:
      return 'authorized';
      
    case AuthorizationTrackingStatus.DENIED:
      return 'denied';
      
    case AuthorizationTrackingStatus.RESTRICTED:
      return 'restricted';
      
    default:
      break;
  }
  
  return 'not_determined';
}

export enum StoreType {
  UNKNOWN,
  APP_STORE,
  GOOGLE_PLAY
}

function getStoreTypeString(storeType: StoreType): string
{
   switch (storeType)
   {
    case StoreType.APP_STORE:
      return 'app_store';
      
    case StoreType.GOOGLE_PLAY:
      return 'google_play';
      
    default:
      break;
  }
  
  return 'other';
}


export abstract class GamejamTrackingCore {
  protected static readonly SEND_URL = "https://api.gamejam.co/tracking/v3/event";
  
  protected static readonly VERSION_KEY = "$version";

  //protected static readonly BUILD_VERSION_KEY = "$build_version";
  //protected static readonly BUILD_NUMBER_KEY = "$build_number";
  
  //protected static readonly APP_VERSION_KEY = "$app_version";
  
  //protected static readonly BUNDLE_ID_KEY = "$bundle_id";
  protected static readonly PLATFORM_KEY = "$plaform";
  
  protected static readonly COUNTRY_KEY = "$country";

  protected static readonly BROWSER_KEY = "$browser";
  protected static readonly BROWSER_VERSION_KEY = "$browser_verison";
  
  protected static readonly DEVICE_MODEL_KEY = "$device_model";
  
  protected static readonly OS_KEY = "$os";
  protected static readonly OS_VERSION_KEY = "$os_version";
  
  //protected static readonly ADVERTISING_ID_KEY = "$advertising_id";
  protected static readonly DEVELOPER_DEVICE_ID_KEY = "$developer_device_id";
  
  //protected static readonly APP_SET_ID_KEY = "$app_set_id";
    
  protected static readonly AD_IMPRESSION = "gjsdk_ad_impression";
  protected static readonly AD_CLICK = "gjsdk_ad_click";
  protected static readonly AD_LOAD = "gjsdk_ad_load";
  protected static readonly AD_CLOSE = "gjsdk_ad_close";

  protected static readonly OPEN = "gjsdk_open";
  protected static readonly FIRST_OPEN = "gjsdk_first_open";
  protected static readonly BOOT_START = "gjsdk_boot_start";
  protected static readonly BOOT_END = "gjsdk_boot_end";

  protected static readonly LEVEL_ATTEMPT = "gjsdk_level_attempt";
  protected static readonly LEVEL_COMPLETE = "gjsdk_level_complete";
  protected static readonly LEVEL_FAIL = "gjsdk_level_fail";

  protected static readonly IAP_INITIALIZATION = "gjsdk_iap_initialization";
  protected static readonly IAP_RESTORE_PURCHASE = "gjsdk_iap_restore_purchase";
  protected static readonly IAP_BUY = "gjsdk_iap_buy";
  protected static readonly IAP_SUCCESS_PAYMENT = "gjsdk_iap_success_payment";
  protected static readonly IAP_FAIL_PAYMENT = "gjsdk_iap_fail_payment";

  protected static readonly FACEBOOK_LOGIN = "gjsdk_fb_login";
  protected static readonly FACEBOOK_LOGOUT = "gjsdk_fb_logout";

  protected static readonly UPDATE_GAME = "gjsdk_update_click";
  protected static readonly RATE_GAME = "gjsdk_store_rating_click";

  protected static readonly SOUND_MODE = "gjsdk_audio_sound_mode";
  protected static readonly AUTHORIZATION_TRACKING_STATUS = "gjsdk_att_authorization_tracking_status";

  protected static readonly ACCOUNT_LOGIN = "gjsdk_account_login";
  protected static readonly ACCOUNT_LOGOUT = "gjsdk_account_logout";
  protected static readonly ACCOUNT_LINK = "gjsdk_account_link";
  protected static readonly ACCOUNT_UNLINK = "gjsdk_account_unlink";

  protected static readonly WALLET_LINK = "gjsdk_account_link_wallet";
  protected static readonly WALLET_UNLINK = "gjsdk_account_unlink_wallet";

  protected static readonly CRYPTO_PAYMENT = "gjsdk_crypto_payment";

  // options
  protected appId: string
     
  protected authCredential: string;
    
  protected userId: string;
  protected sessionId: string;
  protected configId: string;
  
  private soundMode: string;
  
  private isFirstOpen: boolean;
  private bootStartTime: number;

  private flushAt: number
  private flushInterval: number
  private removeDebugCallback?: () => void

  // internal
  protected _events = new SimpleEventEmitter()
  protected _flushTimer?: any
  protected _decideResponsePromise?: Promise<GamejamTrackingDecideResponse>
  protected _retryOptions: RetriableOptions
  protected _sessionExpirationTimeSeconds: number

  // Abstract methods to be overridden by implementations
  abstract fetch(url: string, options: GamejamTrackingFetchOptions): Promise<GamejamTrackingFetchResponse>
  abstract getLibraryId(): string
  abstract getLibraryVersion(): string
  
  abstract getCustomUserAgent(): string | void

  // This is our abstracted storage. Each implementation should handle its own
  abstract getPersistedProperty<T>(key: GamejamTrackingPersistedProperty): T | undefined
  abstract setPersistedProperty<T>(key: GamejamTrackingPersistedProperty, value: T | null): void

  private _optoutOverride: boolean | undefined

  constructor(appId: string, appSecret: string, options?: GamejamTrackingCoreOptions) {
    assert(appId, "You must pass your Gamejam project's app id.")
    assert(appSecret, "You must pass your Gamejam project's app secret.")

    this.appId = appId;
    this.authCredential = Buffer.from(appId + ":" + appSecret, 'binary').toString('base64');
      
    this.bootStartTime = 0;
    
    this.soundMode = '';

    this.flushAt = options?.flushAt ? Math.max(options?.flushAt, 1) : 20
    this.flushInterval = options?.flushInterval ?? 10000
    
    // If enable is explicitly set to false we override the optout
    this._optoutOverride = options?.enable === false
    this._retryOptions = {
      retryCount: options?.fetchRetryCount ?? 3,
      retryDelay: options?.fetchRetryDelay ?? 3000,
    }
    
    this._sessionExpirationTimeSeconds = options?.sessionExpirationTimeSeconds ?? 1800 // 30 minutes

    this.configId = '';
    this.sessionId = '';
    this.userId = '';
    
    this.isFirstOpen = false;
    
    let customUserId = false;
    
    if (options)
    {
      if (!options.waitConfigId ?? true)
      {
        this.configId = 'default';
      }
      
      this.userId = options.userId ?? '';
      
      customUserId = options.customUserId ?? false;
    }
    
    this.init();
    
    this.setupContext();
    
    this.updateUserId(customUserId);
    this.updateSessionId(true);
  }
  
  protected init(options?: GamejamTrackingCoreOptions)
  {
  }
  
  protected setupContext()
  {
    let data: { [key: string]: any } = {};
    this.fillContextData(data);
    this.register(data);
  }
  
  protected fillContextData(data: { [key: string]: any })
  {
    data[GamejamTrackingCore.PLATFORM_KEY] = 'Web';
    data[GamejamTrackingCore.VERSION_KEY] = this.getLibraryVersion();
    
    //TODO: Find a way to get device fingerprint
    data[GamejamTrackingCore.DEVELOPER_DEVICE_ID_KEY] = '';
  }
  
  public setConfigId(configId: string)
  {
    this.configId = configId;
  }

  protected getCommonEventProperties(): any {
    return {
      $lib: this.getLibraryId(),
      $lib_version: this.getLibraryVersion()
    }
  }

  // NOTE: Props are lazy loaded from localstorage hence the complex getter setter logic
  private get props(): GamejamTrackingEventProperties {
    if (!this._props) {
      this._props = this.getPersistedProperty<GamejamTrackingEventProperties>(GamejamTrackingPersistedProperty.Props)
    }
    return this._props || {}
  }

  private set props(val: GamejamTrackingEventProperties | undefined) {
    this._props = val
  }

  private clearProps(): void {
    this.props = undefined
  }

  protected _props: GamejamTrackingEventProperties | undefined

  public get optedOut(): boolean {
    return this.getPersistedProperty(GamejamTrackingPersistedProperty.OptedOut) ?? this._optoutOverride ?? false
  }

  optIn(): void {
    this.setPersistedProperty(GamejamTrackingPersistedProperty.OptedOut, false)
  }

  optOut(): void {
    this.setPersistedProperty(GamejamTrackingPersistedProperty.OptedOut, true)
  }

  on(event: string, cb: (...args: any[]) => void): () => void {
    return this._events.on(event, cb)
  }

  reset(propertiesToKeep?: GamejamTrackingPersistedProperty[]): void {
    const allPropertiesToKeep = [GamejamTrackingPersistedProperty.Queue, ...(propertiesToKeep || [])]

    // clean up props
    this.clearProps()

    for (const key of <(keyof typeof GamejamTrackingPersistedProperty)[]>Object.keys(GamejamTrackingPersistedProperty)) {
      if (!allPropertiesToKeep.includes(GamejamTrackingPersistedProperty[key])) {
        this.setPersistedProperty((GamejamTrackingPersistedProperty as any)[key], null)
      }
    }
  }

  debug(enabled: boolean = true): void {
    this.removeDebugCallback?.()

    if (enabled) {
      this.removeDebugCallback = this.on('*', (event, payload) => console.log('Gamejam Tracking Debug', event, payload))
    }
  }
      
  private updateUserId(customUserId: boolean)
  {
    let cachedUserId: string = this.getCachedUserId();
    if (isNullOrEmpty(this.userId))
    {
      if (isNullOrEmpty(cachedUserId))
      {
        this.isFirstOpen = true;
        
        if (!customUserId)
        {
          this.userId =  generateUUID(globalThis);
          this.setCachedUserId(this.userId);
        }
        else
        {
          this.userId = "";
        }
      }
      else
      {
        this.userId = cachedUserId;
      }
    }
    else
    {
      if (isNullOrEmpty(cachedUserId) || cachedUserId !== this.userId)
      {
        this.isFirstOpen = true;
        this.setCachedUserId(this.userId);
      }
    }
  }
  
  private getCachedUserId(): string
  {
    return this.getPersistedProperty<string>(GamejamTrackingPersistedProperty.UserId) ?? '';
  }
  
  private setCachedUserId(userId: string)
  {
    this.setPersistedProperty(GamejamTrackingPersistedProperty.UserId, userId)
  }

  private updateSessionId(force: boolean = false)
  {
    this.sessionId = this.getPersistedProperty<string>(GamejamTrackingPersistedProperty.SessionId) ?? ''
    
    const sessionTimestamp = this.getPersistedProperty<number>(GamejamTrackingPersistedProperty.SessionLastTimestamp) || 0

    if (isNullOrEmpty(this.sessionId) || Date.now() - sessionTimestamp > this._sessionExpirationTimeSeconds * 1000 || force)
    {
      this.sessionId = generateUUID(globalThis);
      
      this.setPersistedProperty(GamejamTrackingPersistedProperty.SessionId, this.sessionId);
      this.setPersistedProperty(GamejamTrackingPersistedProperty.SessionLastTimestamp, Date.now())
      
      this.trackOpen();
    }
  }

  private resetSessionId()
  {
    this.setPersistedProperty(GamejamTrackingPersistedProperty.SessionId, null)
  }

  register(properties: { [key: string]: any }): void {
    this.props = {
      ...this.props,
      ...properties,
    }
    this.setPersistedProperty<GamejamTrackingEventProperties>(GamejamTrackingPersistedProperty.Props, this.props)
  }

  unregister(property: string): void {
    delete this.props[property]
    this.setPersistedProperty<GamejamTrackingEventProperties>(GamejamTrackingPersistedProperty.Props, this.props)
  }

  private buildPayload(eventName: string, value: JSONObject | null): any
  {
    this.updateSessionId();
    
    return {
      eId: generateUUID(),
      eventName: eventName,
      value: value,
      id: this.sessionId,
      timeInMs: Math.floor(currentTimestamp()),
      cfgId: this.configId
    }
  }

  /***
   *** TRACKING
   ***/
   
  private createTrackBaseData() : JSONObject
  {
    let ret: JSONObject = {};
    return ret;
  }
  
  private createTrackOpenData(
    country: string,
    device: string,
    os_version: string,
    gj_sdk_version: string,
    cfg_id: string,
    developer_device_id: string) : JSONObject
  {
    let ret: JSONObject = {};
    ret['country'] = country;
    ret['device'] = device;
    ret['os_version'] = os_version;
    ret['gj_sdk_version'] = gj_sdk_version;
    ret['cfg_id'] = cfg_id;
    ret['developer_device_id'] = developer_device_id;
    
    return ret;
  }
  
  private createTrackAdData(
    ad_unit: string,
    placement_type: AdPlacementType,
    placement: AdPlacement) : JSONObject
  {
    let ret: JSONObject = {};
    
    if (placement === AdPlacement.UNKNOWN)
    {
      placement = getDefaultAdPlacement(placement_type);
    }
    
    ret['placement_type'] = getAdPlacementTypeString(placement_type);
    ret['placement'] = getAdPlacementString(placement);
    ret['ad_unit'] = ad_unit;
    
    return ret;
  }
  
  private createTrackLevelData(
    level: number,
    label: string) : JSONObject
  {
    let ret: JSONObject = {};
    
    ret['level'] = level;
    ret['label'] = label;
    
    return ret;
  }
  
  private createTrackBootEndData(
    boot_time: number) : JSONObject
  {
    let ret: JSONObject = {};
    ret['boot_time'] = boot_time;
    return ret;
  }
  
  private createTrackIAPInitializationData(
    status: string) : JSONObject
  {
    let ret: JSONObject = {};
    ret['status'] = status;
    return ret;
  }
  
  private createTrackIAPPackageData(
    pack: string,
    price: string,
    amount: number,
    currency: string) : JSONObject
  {
    let ret: JSONObject = {};
    
    ret['pack'] = pack;
    ret['price'] = price;
    ret['amount'] = amount;
    ret['currency'] = currency;
    
    return ret;
  }
  
  private createTrackFacebookData(
    facebook_id: string) : JSONObject
  {
    let ret: JSONObject = {};
    ret['facebook_id'] = facebook_id;
    return ret;
  }
  
  private createTrackUpdateGameData(
    current_version: string,
    new_version: string) : JSONObject
  {
    let ret: JSONObject = {};
    
    ret['current_version'] = current_version;
    ret['new_version'] = new_version;
    
    return ret;
  }
  
  private createTrackRateGameData(
    store_type: StoreType) : JSONObject
  {
    let ret: JSONObject = {};
    ret['store_type'] = getStoreTypeString(store_type);
    return ret;
  }
  
  private createTrackSoundModeData(
    sound_mode: string) : JSONObject
  {
    let ret: JSONObject = {};
    ret['sound_mode'] = sound_mode;
    return ret;
  }
  
  private createTrackAuthorizationTrackingStatusData(
    status: AuthorizationTrackingStatus) : JSONObject
  {
    let ret: JSONObject = {};
    ret['status'] = getAuthorizationTrackingStatusString(status);
    return ret;
  }
  
  private createTrackAccountData(
    id: string,
    type: string) : JSONObject
  {
    let ret: JSONObject = {};
    
    ret['id'] = id;
    ret['type'] = type;
    
    return ret;
  }
  
  private createTrackWalletData(
    wallet: string,
    type: string) : JSONObject
  {
    let ret: JSONObject = {};
    
    ret['wallet'] = wallet;
    ret['type'] = type;
    
    return ret;
  }
  
  private createTrackCryptoPaymentData(
    pack: string,
    price: string,
    amount: number,
    currency: string,
    type: string,
    count: number) : JSONObject
  {
    let ret: JSONObject = {};
    
    ret['pack'] = pack;
    ret['price'] = price;
    ret['amount'] = amount;
    ret['currency'] = currency;
    ret['type'] = type;
    ret['count'] = count;
    
    return ret;
  }
    
  /*
  identify(distinctId?: string, properties?: GamejamTrackingEventProperties): this {
    const previousDistinctId = this.getDistinctId()
    distinctId = distinctId || previousDistinctId

    const payload = {
      ...this.buildPayload({
        distinct_id: distinctId,
        event: '$identify',
        properties: {
          ...(properties || {}),
          $anon_distinct_id: this.getAnonymousId(),
        },
      }),
      $set: properties,
    }

    if (distinctId !== previousDistinctId) {
      // We keep the AnonymousId to be used by decide calls and identify to link the previousId
      this.setPersistedProperty(GamejamTrackingPersistedProperty.AnonymousId, previousDistinctId)
      this.setPersistedProperty(GamejamTrackingPersistedProperty.DistinctId, distinctId)
    }

    this.enqueue('identify', payload)
    return this
  }
  */
  
  private trackOpen()
  {
    let trackValue = this.createTrackOpenData(
      this.getContext(GamejamTrackingCore.COUNTRY_KEY),
      this.getContext(GamejamTrackingCore.OS_VERSION_KEY),
      this.getContext(GamejamTrackingCore.VERSION_KEY),
      this.configId,
      this.getContext(GamejamTrackingCore.VERSION_KEY),
      this.getContext(GamejamTrackingCore.DEVELOPER_DEVICE_ID_KEY)
    );
      
    this.track(this.isFirstOpen ? GamejamTrackingCore.FIRST_OPEN : GamejamTrackingCore.OPEN, trackValue);
    this.isFirstOpen = false;
  }
  
  public trackBootStart()
  {
    this.bootStartTime = Math.floor(currentTimestamp());
    this.track(GamejamTrackingCore.BOOT_START);
  }
  
  public trackBootEnd()
  {
    let bootTime = Math.floor(currentTimestamp()) - this.bootStartTime;
    let trackValue = this.createTrackBootEndData(bootTime);
    
    this.track(GamejamTrackingCore.BOOT_END, trackValue);
  }
  
  public trackLevelStart(id: number, name: string)
  {
    let trackValue = this.createTrackLevelData(id, name);
    this.track(GamejamTrackingCore.LEVEL_ATTEMPT, trackValue);
  }
  
  public trackLevelEnd(id: number, name: string, isSuccess: boolean)
  {
    let trackValue = this.createTrackLevelData(id, name);
    this.track(isSuccess ? GamejamTrackingCore.LEVEL_COMPLETE : GamejamTrackingCore.LEVEL_FAIL, trackValue);
  }
  
  public trackAdLoad(adUnit: string, adPlacementType: AdPlacementType, adPlacement: AdPlacement = AdPlacement.UNKNOWN)
  {
    this.trackAd(GamejamTrackingCore.AD_LOAD, adUnit, adPlacementType, adPlacement);
  }
  
  public trackAdClick(adUnit: string, adPlacementType: AdPlacementType, adPlacement: AdPlacement = AdPlacement.UNKNOWN)
  {
    this.trackAd(GamejamTrackingCore.AD_CLICK, adUnit, adPlacementType, adPlacement);
  }
  
  public trackAdClose(adUnit: string, adPlacementType: AdPlacementType, adPlacement: AdPlacement = AdPlacement.UNKNOWN)
  {
    this.trackAd(GamejamTrackingCore.AD_CLOSE, adUnit, adPlacementType, adPlacement);
  }
  
  public trackAdImpression(adUnit: string, adPlacementType: AdPlacementType, adPlacement: AdPlacement = AdPlacement.UNKNOWN)
  {
    this.trackAd(GamejamTrackingCore.AD_IMPRESSION, adUnit, adPlacementType, adPlacement);
  }
  
  private trackAd(eventName: string, adUnit: string, adPlacementType: AdPlacementType, adPlacement: AdPlacement)
  {
    let trackValue = this.createTrackAdData(adUnit, adPlacementType, adPlacement);
    this.track(eventName, trackValue);
  }
  
  public trackIAPInitialization(isSuccess: boolean)
  {
    let trackValue = this.createTrackIAPInitializationData(isSuccess ? "success" : "failed");
    this.track(GamejamTrackingCore.IAP_INITIALIZATION, trackValue);
  }
  
  public trackIAPRestorePurchase()
  {
    this.track(GamejamTrackingCore.IAP_RESTORE_PURCHASE);
  }
  
  public trackIAPBuyStart(pack: string, price: string, amount: number, currency: string)
  {
    this.trackIAPPackage(GamejamTrackingCore.IAP_BUY, pack, price, amount, currency);
  }
  
  public trackIAPBuyEnd(pack: string, price: string, amount: number, currency: string, isSuccess: boolean)
  {
    this.trackIAPPackage(isSuccess ? GamejamTrackingCore.IAP_SUCCESS_PAYMENT : GamejamTrackingCore.IAP_FAIL_PAYMENT, pack, price, amount, currency);
  }
  
  private trackIAPPackage(eventName: string, pack: string, price: string, amount: number, currency: string)
  {
    let trackValue = this.createTrackIAPPackageData(pack, price, amount, currency);
    this.track(eventName, trackValue);
  }
  
  public trackFacebookLogin(facebookId: string)
  {
    let trackValue = this.createTrackFacebookData(facebookId);
    this.track(GamejamTrackingCore.FACEBOOK_LOGIN, trackValue);
  }
  
  public trackFacebookLogout(facebookId: string)
  {
    let trackValue = this.createTrackFacebookData(facebookId);
    this.track(GamejamTrackingCore.FACEBOOK_LOGOUT, trackValue);
  }
  
  public trackUpdateGame(currentVersion: string, newVersion: string) 
  {
    let trackValue = this.createTrackUpdateGameData(currentVersion, newVersion);
    this.track(GamejamTrackingCore.UPDATE_GAME, trackValue);
  }
  
  public trackRateGame(storeType: StoreType)
  {
    let trackValue = this.createTrackRateGameData(storeType);
    this.track(GamejamTrackingCore.RATE_GAME, trackValue);
  }
  
  private trackSoundMode()
  {
    let trackValue = this.createTrackSoundModeData(this.soundMode);
    this.track(GamejamTrackingCore.SOUND_MODE, trackValue);
  }
  
  public trackAuthorizationTrackingStatus(status: AuthorizationTrackingStatus)
  {
    let trackValue = this.createTrackAuthorizationTrackingStatusData(status);
    this.track(GamejamTrackingCore.AUTHORIZATION_TRACKING_STATUS, trackValue);
  }
  
  public trackAccountLogin(id: string, type: string)
  {
    this.trackAccount(GamejamTrackingCore.ACCOUNT_LOGIN, id, type);
  }
  
  public trackAccountLogout(id: string, type: string)
  {
    this.trackAccount(GamejamTrackingCore.ACCOUNT_LOGOUT, id, type);
  }
  
  public trackAccountLink(id: string, type: string)
  {
    this.trackAccount(GamejamTrackingCore.ACCOUNT_LINK, id, type);
  }
  
  public trackAccountUnlink(id: string, type: string)
  {
    this.trackAccount(GamejamTrackingCore.ACCOUNT_UNLINK, id, type);
  }
  
  private trackAccount(eventName: string, id: string, type: string)
  {
    let trackValue = this.createTrackAccountData(id, type);
    this.track(eventName, trackValue);
  }
  
  public trackWalletLink(wallet: string, type: string = 'eth')
  {
    this.trackWallet(GamejamTrackingCore.WALLET_LINK, wallet, type);
  }
  
  public trackWalletUnlink(wallet: string, type: string = 'eth')
  {
    this.trackWallet(GamejamTrackingCore.WALLET_UNLINK, wallet, type);
  }
  
  private trackWallet(eventName: string, wallet: string, type: string)
  {
    let trackValue = this.createTrackWalletData(wallet, type);
    this.track(eventName, trackValue);
  }
  
  private trackCryptoPayment(pack: string, price: string, amount: number, currency: string, type: string = 'web3', count: number = 1)
  {
    let trackValue = this.createTrackCryptoPaymentData(pack, price, amount, currency, type, count);
    this.track(GamejamTrackingCore.CRYPTO_PAYMENT, trackValue);
  }
  
  public track(eventName: string, value: JSONObject | null = null)
  {
    const payload = this.buildPayload(eventName, value);
    this.enqueue(payload);
  }

  //TODO: Add support for auto track
  /*
  autotrack(eventType: string, elements: GamejamTrackingAutotrackElement[], properties: GamejamTrackingEventProperties = {}): this {
    const payload = this.buildPayload('autotrack',
      properties: {
        ...properties,
        $event_type: eventType,
        $elements: elements,
      },
    })

    this.enqueue('autotrack', payload)
    return this
  }
  */
      
  protected getContext(key: string)
  {
    if (!this._props) return '';
    return this._props[key] || '';
  }

  /***
   *** QUEUEING AND FLUSHING
   ***/
  private enqueue(message: any): void {
    if (this.optedOut) {
      return
    }
    /*
    const message = {
      ..._message,
      type: type,
      library: this.getLibraryId(),
      library_version: this.getLibraryVersion(),
      timestamp: _message.timestamp ? _message.timestamp : currentISOTime(),
    }

    if (message.distinctId) {
      message.distinct_id = message.distinctId
      delete message.distinctId
    }
    */
    
    const queue = this.getPersistedProperty<GamejamTrackingQueueItem[]>(GamejamTrackingPersistedProperty.Queue) || []

    queue.push({ message })
    this.setPersistedProperty<GamejamTrackingQueueItem[]>(GamejamTrackingPersistedProperty.Queue, queue)

    this._events.emit('track', message)

    // Flush queued events if we meet the flushAt length
    if (queue.length >= this.flushAt) {
      this.flush()
    }

    if (this.flushInterval && !this._flushTimer)
    {
      this._flushTimer = safeSetTimeout(() => this.flush(), this.flushInterval)
    }
  }

  flushAsync(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.flush((err, data) => {
        return err ? reject(err) : resolve(data)
      })
    })
  }

  flush(callback?: (err?: any, data?: any) => void): void {
    if (this.optedOut) {
      return callback?.()
    }

    if (this._flushTimer) {
      clearTimeout(this._flushTimer)
      this._flushTimer = null
    }

    const queue = this.getPersistedProperty<GamejamTrackingQueueItem[]>(GamejamTrackingPersistedProperty.Queue) || []

    if (!queue.length)
    {
      return callback?.()
    }
    
    if (isNullOrEmpty(this.userId) || isNullOrEmpty(this.sessionId))
    {
      if (this.flushInterval && !this._flushTimer)
      {
        this._flushTimer = safeSetTimeout(() => this.flush(), this.flushInterval)
      }
    
      return callback?.()
    }

    const items = queue.splice(0, this.flushAt)
    this.setPersistedProperty<GamejamTrackingQueueItem[]>(GamejamTrackingPersistedProperty.Queue, queue)

    const messages = items.map((item) => item.message)

    const data = {
      gjUid: this.userId,
      gjSdkVersion: this.getContext(GamejamTrackingCore.VERSION_KEY),
      country: this.getContext(GamejamTrackingCore.COUNTRY_KEY),
      platform: this.getContext(GamejamTrackingCore.PLATFORM_KEY),
      device: this.getContext(GamejamTrackingCore.DEVICE_MODEL_KEY),
      browser: this.getContext(GamejamTrackingCore.BROWSER_KEY),
      browserVersion: this.getContext(GamejamTrackingCore.BROWSER_VERSION_KEY),
      os: this.getContext(GamejamTrackingCore.OS_KEY),
      osVersion: this.getContext(GamejamTrackingCore.OS_VERSION_KEY),
      subMessages: messages
    }

    const done = (err?: any): void => {
      callback?.(err, messages)
      this._events.emit('flush', messages)
    }

    // Don't set the user agent if we're not on a browser. The latest spec allows
    // the User-Agent header (see https://fetch.spec.whatwg.org/#terminology-headers
    // and https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/setRequestHeader),
    // but browsers such as Chrome and Safari have not caught up.
    const customUserAgent = this.getCustomUserAgent()
    const headers: { [key: string]: string } = {}
    if (customUserAgent) {
      headers['user-agent'] = customUserAgent
    }

    const payload = JSON.stringify(data);

    const fetchOptions: GamejamTrackingFetchOptions =
          {
            method: 'PUT',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + this.authCredential
            },
            body: payload,
          }
          
    this.fetchWithRetry(GamejamTrackingCore.SEND_URL, fetchOptions)
      .then(() => done())
      .catch((err) => {
        if (err.response) {
          const error = new Error(err.response.statusText)
          return done(error)
        }

        done(err)
      })
  }

  private async fetchWithRetry(
    url: string,
    options: GamejamTrackingFetchOptions,
    retryOptions?: RetriableOptions
  ): Promise<GamejamTrackingFetchResponse> {
    return retriable(() => this.fetch(url, options), retryOptions || this._retryOptions)
  }

  async shutdownAsync(): Promise<void> {
    clearTimeout(this._flushTimer)
    await this.flushAsync()
  }

  shutdown(): void {
    void this.shutdownAsync()
  }
}

export * from './types'
export { LZString }
