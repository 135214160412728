import Web3 from 'web3/dist/web3.min.js';
import { TARGET_MAINNET, ChainId, CONTRACT_ADDRESS } from './constants';
import store from '../store';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import trackingManager from '@/utils/tracking-manager';

const NETWORK_CHAIN_ID_MAPS = {
  [TARGET_MAINNET ? 1 : 5]: ChainId.Eth,
  [TARGET_MAINNET ? 56 : 97]: ChainId.Bsc,
};

const WALLET_CONNECTED_KEY = 'fren-wallet-connected';

let web3;
let provider;
let connector;
const appLocalStorage = window.localStorage;

async function init(walletName, isCached) {
  try {
    if (!walletName) return;
    switch (walletName) {
      case 'Metamask':
        if (!window.ethereum || !window.ethereum.isMetaMask) {
          await store.dispatch('showNotification', { message: 'Metamask is not installed!' });
          return;
        }
        if (window.ethereum.providers && window.ethereum.providers.length) {
          provider = window.ethereum.providers.find(p => p.isMetaMask);
        } else {
          provider = window.ethereum;
        }
        break;
      case 'Coinbase':
        if (!window.ethereum) {
          await store.dispatch('showNotification', { message: 'Coinbase is not installed!' });
          return;
        }
        if (window.ethereum.providers && window.ethereum.providers.length) {
          provider = window.ethereum.providers.find(p => p.isCoinbaseWallet);
        } else {
          provider = window.ethereum;
        }
        break;
      case 'Wallet Connect':
        connector = new WalletConnect({
          bridge: "https://bridge.walletconnect.org", // Required
          qrcodeModal: QRCodeModal,
        });
        provider = new WalletConnectProvider({
          rpc: {
            1: "https://mainnet.infura.io/v3/88eb7c595590415da3abf2945b953a1a",
            4: "https://rinkeby.infura.io/v3/88eb7c595590415da3abf2945b953a1a",
            2710: 'https://crypto.gamejam.com/testnet/rpc'
          },
        });
        await provider.enable();
        break;
      default:
        break;
    }

    if (!provider) return;
    if (!isCached) {
      await connect(walletName);
    } else {
      web3 = new Web3(provider);
      window.web3 = web3;
    }

    // init in case listener fails
    provider.on('accountsChanged', changeAccount);
    provider.on('chainChanged', updateChain);
    provider.on('disconnect', () => {
      appLocalStorage.removeItem(WALLET_CONNECTED_KEY);
      trackingManager.logoutTracking();
    });
  } catch (e) {
    console.error('Initialize Wallet error!', e);
  }
}

function getWeb3() {
  return web3;
}

async function connect(walletName) {
  try {
    web3 = new Web3(provider);
    window.web3 = web3;
    if (walletName === 'Wallet Connect') {
      await connectWallet();
    } else {
      await provider.request({ method: 'eth_requestAccounts' });
      await getWalletInfo();
      await store.dispatch('updateWallet', { name: walletName, installed: true, connected: true });
    }
  } catch (error) {
    if (error.code === 4001) {
      await store.dispatch('showNotification', { message: 'User refused to connect! ' });
    }
    console.error(`Failed to connect to ${walletName}!`, error);
  }
}

async function connectWallet() {
  // Check if connection is already established
  if (connector.connected) {
    try {
      await connector.killSession();
    } catch (e) {
      console.error('wc/kill_session', JSON.stringify(e));
    }
  }
  // create new session
  try {
    await connector.createSession();
    // get uri for QR Code modal
    // const uri = connector.uri
    // display QR Code modal
    // QRCodeModal.open(uri, onModalClose)
  } catch (e) {
    console.error('wc/create_session', JSON.stringify(e));
  }

  // Subscribe to connection events
  connector.on("connect", (error, payload) => {
    if (error) {
      console.error('wc/connect', JSON.stringify(error));
    }

    // Close QR Code modal
    QRCodeModal.close();

    store.dispatch('updateWallet', { connected: true, name: 'Wallet Connect' });
    // Get provided accounts and chainId
    const { accounts, chainId } = payload.params[0];
    updateWallet(accounts);
    updateChain(chainId);
  });

  connector.on("session_update", (error, payload) => {
    if (error) {
      console.error('wc/session_update', JSON.stringify(error));
      throw error;
    }

    // Get updated accounts and chainId
    const { accounts, chainId } = payload.params[0];
    updateWallet(accounts);
    updateChain(chainId);
  });

  connector.on("disconnect", (error) => {
    if (error) {
      console.error('wc/disconnect', JSON.stringify(error));
      throw error;
    }
    connector = null;
  });
}

function updateWallet(accounts) {
  const address = accounts.length && accounts[0].toLowerCase() || null;
  const checksumAddress = address && web3.utils.toChecksumAddress(address);
  store.dispatch('updateWallet', {
    address,
    connected: !!checksumAddress
  });
}

function updateChain(network) {
  const chainId = NETWORK_CHAIN_ID_MAPS[Number(network)];
  const wallet = store.getters['getWallet'];
  const currentChainId = wallet && wallet.chainId;
  if (currentChainId !== chainId) {
    store.dispatch('updateWallet', {
      chainId
    });
  }
}

async function switchToChain(chainId) {
  try {
    if (!provider)
      init("Metamask", false);
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }]
    });
    updateChain(chainId);
  } catch (switchError) {
    console.error(switchError);
    if (switchError.code === 4902) {
      try {
        const chain = store.getters.chains.find(chain => chain.chainId === chainId);
        console.log("XXX", chainId, chain);
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [chain]
        });
        await switchToChain(chainId);
      } catch (addError) {
        console.error('Could not add chain!', addError);
      }
    }
  }
}

async function getWalletInfo() {
  const accounts = await web3.eth.getAccounts();
  if (accounts && accounts.length) await updateWallet(accounts);
  const chainId = await web3.eth.getChainId();
  if (chainId) updateChain(chainId);
}

async function getBalance({ address }) {
  try {
    const contract = new web3.eth.Contract(require('@/assets/json/wasd.json'), CONTRACT_ADDRESS.WASD);
    return await contract.methods.balanceOf(address).call();
  } catch (error) {
    console.error(`Failed to get WASD balance of ${address}`, error);
  }
}

async function mint({ amount, address, role_id, sign_hash }) {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  if (role_id && sign_hash)
    return await contract.methods.mintWASD(+role_id, sign_hash, +amount).send({
      from: address
    });
  else
    return await contract.methods.mintWASD(amount).send({
      from: address
    });
}

async function getSignature({ challenge, address }) {
  if (connector)
    return await connector.signPersonalMessage([
      challenge,
      address
    ]);
  else
    return await web3.eth.personal.sign(challenge, address);
}

async function getParticipantInfo(address) {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.getParticipantInfo(address).call();
}

async function getTotalSupply() {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.wasdLimit().call();
}

async function getMintedNFT() {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.totalMintCount().call();
}

async function getCurrentPhase() {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.getCurrentPhase().call();
}

async function getCurrentBlock() {
  return await web3.eth.getBlock('latest');
}

async function getPhaseInfo() {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.getPhaseInfo().call();
}

async function getStarting() {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.startingTime().call();
}

async function getRoles() {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd-minting.json'), store.getters.mintingContract);
  return await contract.methods.getRoles().call();
}

async function getTokenByIndex(index, address) {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd.json'), CONTRACT_ADDRESS.WASD);
  return await contract.methods.tokenOfOwnerByIndex(address, index).call();
}

async function getTokenURI(tokenId) {
  const contract = new web3.eth.Contract(require('@/assets/json/wasd.json'), CONTRACT_ADDRESS.WASD);
  return await contract.methods.tokenURI(tokenId).call();
}

function closeConnector() {
  connector = null;
}

async function changeAccount(accounts) {
  const address = accounts.length && accounts[0].toLowerCase() || null;
  await store.dispatch('switchWallet', {
    address
  });
}

const walletApi = {
  init,
  getWeb3,
  connect,
  switchToChain,
  getBalance,
  mint,
  getSignature,
  getParticipantInfo,
  getTotalSupply,
  getMintedNFT,
  getCurrentPhase,
  getCurrentBlock,
  closeConnector,
  getPhaseInfo,
  getStarting,
  getRoles,
  getTokenByIndex,
  getTokenURI
};

export default walletApi;
