<template>
  <div id="app" :class="theme">
    <header>
      <div style="width: 150px">
        <img @click="goTo('')" alt src="@/assets/images/logo.svg" />
      </div>
      <ul>
        <li>
          <a @click="goTo('')">Home</a>
        </li>
        <li>
          <a :style="{ color: isCurrentRoute('/landing/portal') ? '#825EF9' : '#FAF4EC'}" @click="goTo('portal')">
            Portal
          </a>
        </li>
        <li>
          <a :style="{ color: isCurrentRoute('/landing/playground') ? '#825EF9' : '#FAF4EC'}" @click="goTo('playground')">
            Playground
          </a>
        </li>
        <li>
            <a :style="{ color: isCurrentRoute('/landing/faq') ? '#825EF9' : '#FAF4EC'}" @click="goTo('faq')">
              FAQs
            </a>
          </li>
      </ul>
      <div style="display: flex;flex-direction: row;gap: 10px;">
        <a
          href="https://opensea.io/collection/weallsurviveddeath"
          target="_blank"
          class="header-btn header-btn--buy">Buy a Survivor</a>
        <template v-if="!wallet || !wallet.address">
          <a @click="goTo('login', true)" class="header-btn header-btn--wallet">Connect wallet</a>
        </template>
        <template v-else>
          <div class="header-btn header-btn--wallet" @click="toggleLogout">
            {{ wallet.address | formatAddress }}
            <div v-if="showLogout" class="header-btn btn-logout" @click="logout">
              Logout
            </div>
          </div>
        </template>
      </div>
    </header>
    <div class="header-mobile">
      <img @click="goTo('')" alt src="@/assets/images/logo.svg" />
      <img @click="toggleMenu" alt src="@/assets/images/menu.svg" />
    </div>
    <transition name="slide-down">
      <div v-if="menu" class="header-menu">
        <ul>
          <li>
            <a @click="goTo('')">Home</a>
          </li>
          <li>
            <a :style="{ color: isCurrentRoute('/landing/portal') ? '#825EF9' : '#FAF4EC'}" @click="goTo('portal')">
              Portal
            </a>
          </li>
          <li>
            <a :style="{ color: isCurrentRoute('/landing/playground') ? '#825EF9' : '#FAF4EC'}" @click="goTo('playground')">
              Playground
            </a>
          </li>
          <li>
            <a :style="{ color: isCurrentRoute('/landing/faq') ? '#825EF9' : '#FAF4EC'}" @click="goTo('faq')">
              FAQs
            </a>
          </li>
          <li>
            <a @click="logout">Logout</a>
          </li>
        </ul>
      </div>
    </transition>
    <notification-message />
    <router-view/>
    <footer>
      <div>
        <span>©</span> 2023 We All Survived Death
      </div>
      <div>
        <a href="mailto:hello@wasdnft.com" style="cursor: pointer">hello@wasdnft.com</a>
        <a @click="goTo('faq')" style="cursor: pointer">FAQs</a>
        <a @click="goTo('license')" style="cursor: pointer">Terms Of Use</a>
        <a v-if="inPortalPage" style="cursor: pointer" @click="showRefund">Refund Policy</a>
      </div>
    </footer>
    <div v-if="refundDialog" class="dialog" @click="refundDialog = false">
      <div class="dialog-content" @click.stop style="max-width: 680px">
        <div class="dialog-content_header">
          <div @click="refundDialog = false">x</div>
        </div>
        <div style="max-height: calc(100vh - 80px); overflow: auto">
          <div>Refund Policy for Digital WASX Asset Sales</div>
          <p>All sales of digital WASX assets are final, and no refunds or exchanges will be issued after the asset has been purchased and delivered. By purchasing the digital WASX asset, you agree to these terms and conditions.</p>
          <p>If the digital WASX asset is found to be defective or not as advertised, we may, at our discretion, offer a refund or exchange. To request a refund or exchange, please contact our customer support team within 14 days of the date of purchase, and provide a detailed description of the issue.</p>
          <p>In order to be eligible for a refund or exchange, the digital WASX asset must not have been used or redeemed in any way. If the digital WASX asset has been used or redeemed, we cannot offer a refund or exchange.</p>
          <p>Refunds or exchanges will be processed at our discretion, and may be subject to verification of the issue and confirmation of the purchase. We reserve the right to refuse any refund or exchange request that we determine, in our sole discretion, is not valid or does not comply with these terms and conditions.</p>
          <p>If a refund or exchange is granted, it will be processed within 14 days of the request, using the same payment method used for the original purchase.</p>
          <p>We reserve the right to modify or update this refund policy at any time, without prior notice. Any such modifications or updates will be posted on our website and will be effective immediately upon posting.</p>
          <p>This refund policy applies only to digital WASX assets sold directly by us. We are not responsible for any refunds or exchanges for digital WASX assets sold by third-party sellers or platforms.</p>
          <p>If you have any questions or concerns about our refund policy, please contact our customer support team. By purchasing the digital WASX asset, you acknowledge that you have read, understood, and agree to this refund policy.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationMessage from "@/components/NotificationMessage";
import {mapGetters} from "vuex";
import {runSDK} from '@/utils/superfine-integrate';

export default {
  components: {NotificationMessage},
  filters: {
    formatAddress(v) {
      if (v && typeof v === 'string')
        return v.slice(0, 5) + '...' + v.slice(-5)
      return v
    }
  },
  data() {
    return {
      menu: false,
      showLogout: false,
      inPortalPage: this.$route.path === '/landing/portal',
      refundDialog: false
    }
  },
  created() {
    runSDK()
  },
  computed: {
    ...mapGetters(['theme', 'wallet'])
  },
  watch:{
    $route(to) {
      if (to.path === '/landing/portal')
        this.inPortalPage = true;
    }
  },
  methods: {
    toggleMenu() {
      const body = document.scrollingElement;
      body.style.overflow = this.menu ? "" : "hidden";
      this.menu = !this.menu;
    },
    goTo(page, redirect = false) {
      if (this.menu) {
        const body = document.scrollingElement;
        body.style.overflow = ""
        this.menu = false;
      }
      if (redirect) {
        const path = this.$route.path
        const paths = window.location.pathname.split('/').filter(p => !!p)
        const index = paths.findIndex(p => p.toLowerCase() === 'landing')
        const prevPath = paths.slice(0, index)
        window.location.href = `${prevPath.length ? '/' + prevPath.join('/') : ''}/landing/${page}?redirect=${path}`
      } else {
        this.$router.push({
          path: `/landing/${page}`
        })
      }
    },
    toggleLogout() {
      this.showLogout = true
      setTimeout(() => {
        this.showLogout = false
      }, 2000)
    },
    logout() {
      this.$store.dispatch('logout')
      this.toggleMenu()
    },
    isCurrentRoute(route) {
      return this.$route.path === route
    },
    showRefund() {
      this.refundDialog = true
    }
  }
}

/* eslint-disable */
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '932053611526361');
fbq('track', 'PageView');
/* eslint-enable */
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #FAF4EC;
  background: #000000;
  text-align: left;
  position: relative;
  overflow: hidden;

  * {
    letter-spacing: normal;
    font-family: "Noto Sans", sans-serif;
  }

  ul {
    padding: 0;
  }

  header {
    padding: 8px max(20px, calc(50vw - 560px));
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: linear-gradient(180deg, #181818 0%, rgba(24, 24, 24, 0.25) 50%, #181818 100%);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);

    img {
      cursor: pointer;
      height: 36px;
    }

    ul {
      display: flex;
      align-items: center;
      flex-grow: 1;

      li {
        list-style-type: none;
        cursor: pointer;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        margin: 0 15px;
        text-transform: uppercase;

        a {
          text-decoration: none;
          color: #FAF4EC;
        }
      }
    }
  }

  .header-menu {
    display: none;
  }

  .header-mobile {
    display: none;
  }

  footer {
    margin-top: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #181818 0%, rgba(24, 24, 24, 0) 50.15%, #181818 100%);
    backdrop-filter: blur(4px);
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    padding: 30px max(20px, calc(50vw - 560px));
    z-index: 1;

    span {
      font-size: 15px;
      line-height: 20px;
    }

    a {
      color: #FAF4EC;
      text-decoration: none;
      display: block;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      margin: 10px 0;
    }
  }

  .footer-video {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
  }

  .header-btn {
    padding: 7px;
    cursor: pointer;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 83.33%;
    text-align: center;
    text-decoration: none;
    width: 120px;

    &--buy {
      background: #FFE42E;
      color: #181818;
    }

    &--wallet {
      background: #825ef9;
      color: #FAF4EC;
      position: relative;

      .btn-logout {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        right: 0;
        background: #FAF4EC;
        color: #181818;
      }
    }

    &:hover {
      box-shadow: 0 3px 1px -2px rgba(102, 116, 245, 0.2),
      0 2px 2px 0 rgba(102, 116, 245, 0.14),
      0 1px 5px 0 rgba(102, 116, 245, 0.12);
    }
  }

  .loading {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
    border-radius: 9999px;
    border-width: 2px;
    border-style: solid;
    border-top-color: #18181818;
    border-left-color: #18181818;
    border-bottom-color: currentColor;
    border-right-color: currentColor;
    animation: spin 2s linear infinite;
    transform-origin: center;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    &--big {
      margin: 16px;
      width: 40px;
      height: 40px;
      border-width: 4px;
    }
  }
}

@media screen and (max-width: 1024px) {
  #app {
    overflow-y: scroll;
    height: 100vh;

    &::-webkit-scrollbar {
      display: none;
    }

    header {
      display: none;
    }

    .header-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background: #181818;
      z-index: 100;

      img {
        max-height: 32px;
      }
    }

    .header-menu {
      background: #181818;
      position: fixed;
      top: 48px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 100;

      ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          list-style-type: none;
          cursor: pointer;
          font-family: "Noto Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          margin: 16px;

          a {
            text-decoration: none;
          }
        }
      }

      .socials {
        padding: 16px;
      }
    }

    .footer-video {
      display: none;
    }

    footer {
      flex-direction: column;
      justify-content: center;

      div a {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #app {
    footer {
      padding: 16px;

      div a {
        margin: 4px 0;
      }
    }
  }
}
</style>
